import { VOUCHER_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    vouchers: [],
};

const voucherReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case VOUCHER_DATA: {
            return {
                ...state,
                vouchers: action.payload,
            }
        }
        default:
            return state;
    }
}

export default voucherReducer;
