import { BUILDING_DATA,UPDATE_LOADER } from "../../constants/ActionTypes";

const INIT_STATE = {
    buildings: [],
    loading: false,
};

const buildingReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case BUILDING_DATA: {
        return {
          ...state,
          buildings: action.payload,
        }
      }
  
      case UPDATE_LOADER: {
        return {
          ...state,
          loading: action.payload
        };
      }
  
      default:
        return state;
    }
}

export default buildingReducer;
  