import { ACC_LEVELS_DATA, DEFAULT_ACCESS_LEVEL, DEPARTMENTS_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    departments: [],
    accLevels: [],
    defaultAccessLevel: null
};

const accessControlReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DEPARTMENTS_DATA: {
            return {
                ...state,
                departments: action.payload,
            }
        }
        case ACC_LEVELS_DATA: {
            return {
                ...state,
                accLevels: action.payload,
            }
        }
        case DEFAULT_ACCESS_LEVEL: {
            return {
                ...state,
                defaultAccessLevel: action.payload,
            }
        }
        default:
            return state;
    }
}

export default accessControlReducer;
