import axios from "axios";

//export const IMAGE_URL = "http://154.0.162.255/api/storage/";
//export const IMAGE_URL = "http://127.0.0.1:8000/api/storage/";
export const IMAGE_URL = "https://api.city-life.co.za/storage/";

const HttpMethod = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export default axios.create({
  baseURL: "https://api.city-life.co.za/api",
  //baseURL: "http://127.0.0.1:8000/api/",
  //baseURL: "http://154.0.162.255/api/api/",
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
});

export class APiHelper{

    get(url){
        return new Promise((resolve, reject)=> {
            axios.get().then(({ data }) => {
                console.log("data: ", data.data);
                if (data.success) {
                    resolve(data.data);
                } else{
                    resolve(data.error);
                }
            }).catch(function (error) {
                console.log("Error****:", error);
                if (error.response.status === 401) {
                    
                }
                resolve(error.message);
            });
        });
    }
}




export class ApiHelperXXX {
  url = "";
  method = HttpMethod.get;
  queryParams = {};
  responseWithHeader = false;
  body;
  isTokenRequired = true;

  //http post method ***
  post(url, body = undefined) {
    this.url = url;
    this.body = body;
    this.method = HttpMethod.post;
    return this;
  }
  //http get method ****
  get(url) {
    this.url = url;
    return this;
  }

  //http put method ***
  put(url, body = undefined) {
    this.url = url;
    this.body = body;
    this.method = HttpMethod.put;
    return this;
  }

  //http delete method ****
  delete(url) {
    this.url = url;
    this.method = HttpMethod.delete;
    return this;
  }

  withHeaders(headers) {
    this.headers = {
      ...this.headers,
      ...headers,
    };
    return this;
  }

  withQueryParams(queryParams) {
    if (queryParams) {
      this.queryParams = queryParams;
    }
    return this;
  }

  // if checking token validity is not required
  // for example: sign in api or any api before sign in ***
  withoutToken() {
    this.isTokenRequired = false;
    return this;
  }

    get config() {
        return {
            endpoint: this.url,
            method: this.method,
            headers: this.headers,
            queryParams: this.queryParams,
            body: this.body
        };
    }
}
