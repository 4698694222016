import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import { LogoutOutlined } from '@ant-design/icons';
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import { userSignOut } from "../../appRedux/actions/Auth";
import { UserRole } from "../../enums/UserRole";

const SubMenu = Menu.SubMenu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {

    const dispatch = useDispatch();
    let { navStyle, themeType } = useSelector(({ settings }) => settings);
    const { authUser } = useSelector(({ auth }) => auth);
    let { pathname } = useSelector(({ common }) => common);

    const getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return "gx-no-header-notifications";
        }
        return "";
    };

    const getNavStyleSubMenuClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return "gx-no-header-submenu-popup";
        }
        return "";
    };

    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <>
        <SidebarLogo
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
          >
            <UserProfile />
            {/* <AppsNavigation /> */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              {authUser.role === UserRole.SUPER_ADMIN ? (
                <Menu.Item key="management">
                  <Link to="/management">
                    <i className="icon icon-dasbhoard" />
                    <span>
                      <IntlMessages id="sidebar.dashboard" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : (
                <Menu.Item key="admin">
                  <Link to="/admin">
                    <i className="icon icon-dasbhoard" />
                    <span>
                      <IntlMessages id="sidebar.dashboard" />
                    </span>
                  </Link>
                </Menu.Item>
              )}

              <SubMenu
                key="apartments"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-crm" />
                    <span>
                      <IntlMessages id="sidebar.buildingDetails" />
                    </span>
                  </span>
                }
              >
                {authUser.role === UserRole.SUPER_ADMIN && (
                  <Menu.Item key="buildings">
                    <Link to="/buildings">
                      <i className="icon icon-company" />
                      <span>
                        <IntlMessages id="sidebar.buildings" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="apartment-list">
                  <Link to="/apartment-list">
                    <i className="icon icon-home" />
                    <span>
                      <IntlMessages id="sidebar.apartments" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="tenants">
                  <Link to="/tenants">
                    <i className="icon icon-widgets" />
                    <span>
                      <IntlMessages id="sidebar.tenants" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="staffs">
                  <Link to="/staffs">
                    <i className="icon icon-widgets" />
                    <span>
                      <IntlMessages id="sidebar.staffs" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              {authUser.role === UserRole.SUPER_ADMIN && (
                <Menu.Item key="tenants/blacklisted">
                  <Link to="/tenants/blacklisted">
                    <i className="icon icon-user" />
                    <span>
                      <IntlMessages id="sidebar.blackListedTenants" />
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {authUser.role === UserRole.SUPER_ADMIN && (
                <Menu.Item key="users">
                  <Link to="/users">
                    <i className="icon icon-family" />
                    <span>
                      <IntlMessages id="sidebar.users" />
                    </span>
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item key="visitors">
                <Link to="/visitors">
                  <i className="icon icon-family" />
                  <span>
                    <IntlMessages id="sidebar.visitors" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="vouchers">
                <Link to="/vouchers">
                  <i className="icon icon-tag" />
                  <span>
                    <IntlMessages id="sidebar.vouchers" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="reports">
                <Link to="/reports">
                  <i className="icon icon-tasks" />
                  <span>
                    <IntlMessages id="sidebar.reports" />
                  </span>
                </Link>
              </Menu.Item>

              {authUser.role === UserRole.SUPER_ADMIN && (
                <SubMenu
                  key="settings"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      {" "}
                      <i className="icon icon-setting" />
                      <span>
                        <IntlMessages id="sidebar.settings" />
                      </span>
                    </span>
                  }
                >
                  <Menu.Item key="mobile-app-settings">
                    <Link to="/mobile-app-settings">
                      <i className="icon icon-setting" />
                      <span>
                        <IntlMessages id="sidebar.mobile-app-settings" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="payment-account">
                    <Link to="/payment-account">
                      <i className="icon icon-bitcoin" />
                      <span>
                        <IntlMessages id="sidebar.paymentAccount" />
                      </span>
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="general-settings">
                    <Link to="/general-settings">
                      <i className="icon icon-schedule" />
                      <span>
                        <IntlMessages id="sidebar.generalSettings" />
                      </span>
                    </Link>
                  </Menu.Item> */}
                  {/* <Menu.Item key="sms-settings">
                                <Link to="/sms-settings">
                                    <i className="icon icon-chat" />
                                    <span><IntlMessages id="sidebar.sms-settings" /></span>
                                </Link>
                            </Menu.Item> */}
                </SubMenu>
              )}

              <Menu.Item key="logout">
                <Link to={"/signin"} onClick={() => dispatch(userSignOut())}>
                  <LogoutOutlined />
                  <span>
                    <IntlMessages id="sidebar.logout" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu>
          </CustomScrollbars>
        </div>
      </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

