import { APARTMENT_ACCESS_CONTROL, APARTMENT_DATA, APARTMENT_DATA_WITHOUT_TENANTS, APARTMENT_DETAILS, APARTMENT_PAYMENT, APARTMENT_WARNINGS } from "../../constants/ActionTypes";

const INIT_STATE = {
    apartments: null,
    apartmentsWithoutTenant: [],
    payments: [],
    warnings: [],
    accessControl: [],
    apartment: null
};

const apartmentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case APARTMENT_DATA: {
            return {
                ...state,
                apartments: action.payload,
            }
        }
        case APARTMENT_DATA_WITHOUT_TENANTS: {
            return {
                ...state,
                apartmentsWithoutTenant: action.payload,
            }
        }
        case APARTMENT_DETAILS: {
            return {
                ...state,
                apartment: action.payload,
            }
        }
        case APARTMENT_PAYMENT: {
            return {
                ...state,
                payments: action.payload,
            }
        }
        case APARTMENT_ACCESS_CONTROL: {
            return {
                ...state,
                accessControl: action.payload,
            }
        }
        case APARTMENT_WARNINGS: {
            return {
                ...state,
                warnings: action.payload,
            }
        }
        default:
            return state;
    }
}

export default apartmentReducer;
