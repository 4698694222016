import {combineReducers} from "redux";
import Settings from "../reducers/Settings";
import Auth from "../reducers/Auth";
import buildingReducer from "../reducers/BuildingReducer";
import apartmentReducer from "../reducers/ApartmentReducer";
import visitorReducer from "../reducers/VisitorReducer";
import voucherReducer from "../reducers/VoucherReducer";
import serviceReducer from "../reducers/ServiceReducer";
import userReducer from "../reducers/UserReducer";
import staffReducer from "../reducers/StaffReducer";
import dashboardReducer from "../reducers/DashboardReducer";
import reportReducer from "../reducers/ReportReducer";
import accessControlReducer from "../reducers/AccessControlReducer";
import mobileAppSettingsReducer from "../reducers/MobileAppSettingsReducer";
import tenantsReducer from "../reducers/TenantsReducer";
import Common from "../reducers/Common";
import {connectRouter} from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { SIGNOUT_USER_SUCCESS } from '../../constants/ActionTypes';

const createBrowserHistory = require('history').createBrowserHistory;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];

const appReducer = combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    buildingReducer: buildingReducer,
    apartmentReducer: apartmentReducer,
    visitorReducer: visitorReducer,
    voucherReducer: voucherReducer,
    serviceReducer: serviceReducer,
    userReducer: userReducer,
    mobileAppSettingsReducer: mobileAppSettingsReducer,
    dashboardReducer: dashboardReducer,
    reportReducer: reportReducer,
    accessControlReducer: accessControlReducer,
    staffReducer: staffReducer,
    tenantsReducer: tenantsReducer
});

export const rootReducer = (state, action) => {
    if (action.type === SIGNOUT_USER_SUCCESS) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
}

export default function configureStore(preloadedState = {}) {
    return createStore(
        rootReducer, // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                ...middlewares
            ),
        ),
    );
}
