import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SIGNOUT_USER_SUCCESS, UPDATE_LOAD_USER,
    USER_DATA,
    USER_LIST_DATA,
    USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from 'util/Api'

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const userSignUp = ({ email, password, name }) => {
    console.log(email, password);
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('register', {
            email: email,
            password: password,
            name: name
        }
        ).then(({ data }) => {
            console.log("data:", data);
            if (data.result) {
                localStorage.setItem("token", data.token.access_token);
                axios.defaults.headers.common['authorization'] = "Bearer " + data.token.access_token;
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
                dispatch({ type: USER_DATA, payload: data.user });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_ERROR, payload: "Network Error" });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const userSignIn = ({ email, password }) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('login', {
            email: email,
            password: password,
        }
        ).then(({ data }) => {
            console.log("userSignIn: ", data);
            if (data.success) {
                localStorage.setItem("token", data.token);
                axios.defaults.headers.common['Authorization'] = "Bearer " + data.token;
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: USER_TOKEN_SET, payload: data.token });
                dispatch(getUser(data.token));
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const getUser = (token) => {
    return (dispatch) => {
        if (!token) {
            const token = localStorage.getItem('token');
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        dispatch({ type: FETCH_START });
        axios.get('me').then(({ data }) => {
            console.log("userData: ", data);
            if (data?.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: USER_DATA, payload: data.user });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.status });
                dispatch({ type: UPDATE_LOAD_USER, payload: false });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                dispatch({ type: SIGNOUT_USER_SUCCESS });
            }
            else{
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: UPDATE_LOAD_USER, payload: false });
            }
            
            console.log("Error****:", error.response.status);
        });
    }
};

export const userSignOut = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token');
        dispatch({ type: FETCH_START });
        axios.post('logout', { token: token }).then(({ data }) => {
            console.log("log out", data)
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: SIGNOUT_USER_SUCCESS });
                localStorage.removeItem("token");
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchUserList = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('users').then(({ data }) => {
            console.log("data: ", data.data);
            if (data.success) {
                dispatch({ type: USER_LIST_DATA, payload: data.data });
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                dispatch({ type: SIGNOUT_USER_SUCCESS });
            }
            else{
                dispatch({ type: FETCH_ERROR, payload: error.message });
            }
            console.log("Error****:", error.message);
        });
    }
}