import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { UserRole } from "../enums/UserRole";
import Error401 from "../ui/errors/Error401";
import ProtectedRoute from "./ProtectedRoute";

const App = ({ match }) => {

    const routes = [
        { 
            path: `${match.url}management`, 
            component: asyncComponent(() => import('../ui/ManagementDashboard')),
            allowedRoles: [UserRole.SUPER_ADMIN]
        },
        { 
            path: `${match.url}admin`, 
            component: asyncComponent(() => import('../ui/AdminDashboad')),
            allowedRoles: [UserRole.ADMIN]
        },
        { 
            path: `${match.url}buildings`, 
            component: asyncComponent(() => import('../ui/buildings/BuildingDetails')),
            allowedRoles: [UserRole.SUPER_ADMIN]
        },
        { 
            path: `${match.url}apartment-settings/:id`, 
            component: asyncComponent(() => import('../ui/apartments/ApartmentSettings')),
            allowedRoles: [UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        { 
            path: `${match.url}users`, 
            component: asyncComponent(() => import('../ui/users/UserList')),
            allowedRoles: [UserRole.SUPER_ADMIN]
        },
        { 
            path: `${match.url}reports`, 
            component: asyncComponent(() => import('../ui/reports/Reports')),
            allowedRoles: [UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        { 
            path: `${match.url}tenants`, 
            component: asyncComponent(() => import('../ui/tenants/TenantList')),
            allowedRoles: [UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        { 
            path: `${match.url}staffs`, 
            component: asyncComponent(() => import('../ui/staffs/StaffList')),
            allowedRoles: [UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        { 
            path: `${match.url}apartment-list`, 
            component: asyncComponent(() => import('../ui/apartments/ApartmentList')),
            allowedRoles: [UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        { 
            path: `${match.url}visitors`, 
            component: asyncComponent(() => import('../ui/visitors/VisitorList')),
            allowedRoles: [UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        { 
            path: `${match.url}vouchers`, 
            component: asyncComponent(() => import('../ui/vouchers/VoucherList')),
            allowedRoles: [UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        { 
            path: `${match.url}mobile-app-settings`, 
            component: asyncComponent(() => import('../ui/settings/MobileAppSettings')),
            allowedRoles: [UserRole.SUPER_ADMIN]
        },
        { 
            path: `${match.url}tenants/blacklisted`, 
            component: asyncComponent(() => import('../ui/tenants/BlackListedTenantList')),
            allowedRoles: [UserRole.SUPER_ADMIN]
        },
        { 
            path: `${match.url}payment-account`, 
            component: asyncComponent(() => import('../ui/settings/paymentAccount/PaymentAccountSettings')),
            allowedRoles: [UserRole.SUPER_ADMIN]
        },
        { 
            path: `${match.url}general-settings`, 
            component: asyncComponent(() => import('../ui/settings/GeneralSettings')),
            allowedRoles: [UserRole.SUPER_ADMIN]
        }
    ];

    return (
        <div className="gx-main-content-wrapper">
            <Switch>
                <Route exact path='/unauthorized' component={Error401} />
                {
                    routes.map(route => <ProtectedRoute key={route.path} exact {...route}/>)
                }
            </Switch>
        </div>
    );
};

export default App;
