import { SERVICE_DATA, SERVICE_DATA_HAS_CODE } from "../../constants/ActionTypes";

const INIT_STATE = {
    services: [],
    servicesHasCode: []
};

const serviceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SERVICE_DATA: {
            return {
                ...state,
                services: action.payload,
            }
        }
        case SERVICE_DATA_HAS_CODE: {
            return {
                ...state,
                servicesHasCode: action.payload,
            }
        }
        default:
            return state;
    }
}

export default serviceReducer;
