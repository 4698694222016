import { VISITOR_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    visitors: [],
};

const visitorReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case VISITOR_DATA: {
            return {
                ...state,
                visitors: action.payload,
            }
        }
        default:
            return state;
    }
}

export default visitorReducer;
