import { Redirect, Route } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
const ProtectedRoute = ({ allowedRoles, ...props }) => {
    const { authUser } = useSelector(({ auth }) => auth);
    console.log('auth role ',authUser?.role);
    console.log('allowedRoles ',allowedRoles);

    return (
        allowedRoles.find(role=> role === authUser?.role) ? <Route {...props}/> : <Redirect to="/unauthorized" />
    );
}

export default ProtectedRoute;