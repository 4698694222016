import { USER_LIST_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    users: []
};

const userReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_LIST_DATA: {
            return {
                ...state,
                users: action.payload,
            };
        }

        default:
            return state;
    }
}

export default userReducer;