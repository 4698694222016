import { REPORT_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    reportData: [],
};

const reportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case REPORT_DATA: {
            return {
                ...state,
                reportData: action.payload,
            }
        }
        default:
            return state;
    }
}

export default reportReducer;
