import {  BLACKLISTED_TENANTS_DATA, TENANTS_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    blackListedTenants: [],
    tenants: []
};

const tenantsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case BLACKLISTED_TENANTS_DATA: {
            return {
                ...state,
                blackListedTenants: action.payload,
            }
        }
        case TENANTS_DATA: {
            return {
                ...state,
                tenants: action.payload,
            }
        }
        default:
            return state;
    }
}

export default tenantsReducer;
